import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface Page404Props {}

const Page404: FunctionComponent<Page404Props> = () => {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-4">
      <div className="flex items-center justify-center gap-4">
        <div className="flex h-52 w-52 items-center justify-center rounded-full bg-gray-600 text-9xl text-white">
          4
        </div>
        <div className="flex h-52 w-52 items-center justify-center rounded-full bg-gray-600 text-9xl text-white">
          0
        </div>
        <div className="flex h-52 w-52 items-center justify-center rounded-full bg-gray-600 text-9xl text-white">
          4
        </div>
      </div>
      <div className="mt-8 text-4xl font-bold">Page Not Found!</div>
      <Link
        to="/"
        className="rounded-md bg-gray-600 px-6 py-4 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
      >
        Back To Home
      </Link>
    </div>
  );
};

export default Page404;

import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import {
  retrieveCompaniesList,
  retrieveProjectBySlug,
  retrieveUsersList,
} from "../../services/getServices";
import SelectOptions from "../common/SelectOptions";
import TextInput from "../common/TextInput";
import { updateProject } from "../../services/updateServices";
import { deleteProject } from "../../services/deleteServices";
import ROUTES from "../../routes/ROUTES";

type Tproject = {
  [keys: string]: any;
};

interface ProjectProps {}

const Project: FunctionComponent<ProjectProps> = () => {
  const { admin } = ROUTES;
  const { slug } = useParams();
  const redirect = useNavigate();

  const [options, setOptions] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Tproject[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Tproject>();
  const [project, setProject] = useState<Tproject>({});
  const [accessedUsers, setAccessedUsers] = useState<Tproject>([]);

  useEffect(() => {
    setLoading(true);
    retrieveProjectBySlug(slug)
      .then((resP) => {
        setProject(resP.data);
        retrieveCompaniesList()
          .then((resC) => {
            const companiesOptions = resC.data.map((company: Tproject) => {
              if (company.id === resP.data.company)
                setSelectedCompany({
                  label: company.name,
                  value: company.id,
                });
              return { label: company.name, value: company.id };
            });
            setCompanies(companiesOptions);
          })
          .catch((err) => {
            redirect(admin.projects);
          });
        retrieveUsersList().then((resU) => {
          const options = resU.data.map((user: Tproject) => {
            return { label: user.username, value: user.id };
          });
          const accessedUsers = resU.data.map((user: Tproject) => {
            return (
              resP.data.users.includes(user.id) && {
                label: user.username,
                value: user.id,
              }
            );
          });
          setOptions(options);
          setAccessedUsers(accessedUsers.filter(Boolean));
          setLoading(false);
        });
      })
      .catch((err) => {
        redirect(admin.projects);
      })
      .catch((err) => {
        redirect(admin.projects);
      });
  }, []);

  useEffect(() => {
    const userIds = accessedUsers.map((user: any) => user.value);
    setProject({ ...project, users: userIds });
  }, [accessedUsers]);

  const changeInputsValueHandler = (key: string, value: string) => {
    setProject({ ...project, [key]: value });
  };
  const closeModalHandler = () => setShowModal(false);
  const deleteProjectHandler = () => {
    closeModalHandler();
    redirect(admin.projects);
    deleteProject(slug).then(() => toast.success(`Project ${slug} deleted!`));
  };

  const updateProjectHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (project.name && project.url && project.slug) {
      setLoading(true);
      updateProject(slug, { ...project }).then((res) => {
        setProject(res.data);
        redirect(admin.projects + "/" + res.data.slug, { replace: true });
        toast.success("Project details updated!");
        setLoading(false);
      });
    } else toast.error("All fields are required!");
  };
  return loading ? (
    <div className="h-96 w-full">
      <Skeleton baseColor="#d4d4d8" count={4.5} className="h-8" />
      <Skeleton baseColor="#d4d4d8" count={0.5} className="h-8" />
      <Skeleton baseColor="#d4d4d8" count={4.5} className="h-8" />
    </div>
  ) : (
    <form className="px-8" onSubmit={updateProjectHandler}>
      <div
        onClick={closeModalHandler}
        className={`fixed inset-0 h-screen w-screen transition-all ${
          showModal ? "z-20 backdrop-blur-sm" : "-z-20"
        }`}
      ></div>
      <div
        className={`fixed left-1/2 w-fit -translate-x-1/2 rounded-md bg-gray-500 px-12 py-4 text-white shadow-lg transition-all ${
          showModal ? "z-30 opacity-100" : "-z-20 opacity-0"
        }`}
      >
        <span>Are you sure?!</span>
        <div className="mt-4 flex gap-4">
          <button
            type="button"
            onClick={closeModalHandler}
            className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            NO!
          </button>
          <button
            onClick={deleteProjectHandler}
            type="button"
            className="rounded-md border-2 border-gray-600 bg-white px-12 py-2 text-gray-600 transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            Delete!
          </button>
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        <TextInput
          id="name"
          value={project.name}
          label="Name"
          onChange={(value) => changeInputsValueHandler("url", value)}
        />
        <TextInput
          id="url"
          value={project.url}
          label="Url"
          onChange={(value) => changeInputsValueHandler("url", value)}
        />
        <TextInput
          id="slug"
          value={project.slug}
          label="Slug"
          onChange={(value) => changeInputsValueHandler("slug", value)}
        />
        <div className="w-1/4">
          <SelectOptions
            label="Company"
            placeholder="Optional"
            options={companies}
            isMulti={false}
            value={selectedCompany}
            onChange={(value) => {
              setSelectedCompany(value);
              changeInputsValueHandler("company", value.value);
            }}
          />
        </div>
      </div>
      <div className="mt-4 w-3/4 sm:w-1/2 lg:w-1/4">
        <SelectOptions
          label="Users"
          value={accessedUsers}
          isMulti={true}
          options={options}
          onChange={setAccessedUsers}
        />
      </div>
      <div className="mt-8">
        <Link
          to={`/projects/${project.slug}`}
          className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Show Project
        </Link>
      </div>
      <div className="mt-4 flex gap-4">
        <button
          type="submit"
          className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Save
        </button>
        <Link
          to={admin.projects}
          className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Back
        </Link>
        <button
          onClick={() => setShowModal(true)}
          type="button"
          className="rounded-md border-2 border-gray-600 bg-white px-12 py-2 text-gray-600 transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Delete Project
        </button>
      </div>
    </form>
  );
};

export default Project;

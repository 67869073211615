import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import ROUTES from "../../routes/ROUTES";
interface SidebarProps {}

const Sidebar: FunctionComponent<SidebarProps> = () => {
  const { admin } = ROUTES;
  const navLinks = [
    { name: "Users", path: admin.users },
    { name: "Projects", path: admin.projects },
    { name: "Companies", path: admin.companies },
  ];
  return (
    <nav className="  flex min-w-[20%] gap-4 px-8">
      <div className="flex min-h-[25rem] w-full flex-col gap-2 rounded-md bg-gray-500 p-2">
        {navLinks.map((navLink) => (
          <NavLink
            key={navLink.path}
            to={navLink.path}
            className={({ isActive }) =>
              isActive ? "sidebarItem sidebarItemActive" : "sidebarItem"
            }
          >
            {navLink.name}
          </NavLink>
        ))}
      </div>
    </nav>
  );
};

export default Sidebar;

import http from "./httpServices";

export const loginUser = async (data) => {
  return await http.post("auth/api/token/", data);
};

export const createUser = async (data) => {
  return await http.post("auth/create/", data);
};

export const createProject = async (data) => {
  return await http.post("user/project/", data);
};

export const createCompany = async (data) => {
  return await http.post("user/company/", data);
};

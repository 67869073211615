const ROUTES = {
  admin: {
    users: "/admin/users",
    projects: "/admin/projects",
    companies: "/admin/companies",
    createUser: "/admin/create-user",
    createProject: "/admin/create-project",
    createCompany: "/admin/create-company",
  },
  auth: {
    login: "/login",
  },
  projects: "/projects",
};
export default ROUTES;

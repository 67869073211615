import { FormEvent, FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "../../routes/ROUTES";
import { createCompany } from "../../services/postServices";
import Spinner from "../common/Spinner";
import TextInput from "../common/TextInput";

interface CreateCompanyProps {}

const CreateCompany: FunctionComponent<CreateCompanyProps> = () => {
  const { admin } = ROUTES;
  const redirect = useNavigate();

  const [company, setCompany] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);

  const changeInputsValueHandler = (value: string) => {
    setCompany({ name: value });
  };

  const createUserHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (company.name) {
      setLoading(true);
      createCompany(company)
        .then((res) => {
          setCompany(res.data);
          redirect(admin.companies + "/" + res.data.name);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    } else toast.error("Company name is required!");
  };

  return (
    <div className="mx-auto flex h-fit w-fit flex-col items-center gap-2 rounded-lg bg-white p-4 shadow-md">
      <h1 className="font-bold lg:text-2xl">Create Company</h1>
      <form onSubmit={createUserHandler}>
        <div className="flex flex-col gap-4 px-8">
          <TextInput
            id="name"
            value={company.name}
            label="Name"
            onChange={(value) => changeInputsValueHandler(value)}
          />
          <button
            className="flex w-full justify-center rounded-md bg-gray-600 py-2 text-white"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCompany;

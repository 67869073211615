import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  retrieveProjectsList,
  retrieveUserById,
} from "../../services/getServices";
import SelectOptions from "../common/SelectOptions";
import TextInput from "../common/TextInput";
import { updateUser, updateUserPassword } from "../../services/updateServices";
import { Link } from "react-router-dom";
import ROUTES from "../../routes/ROUTES";
import { deleteUser } from "../../services/deleteServices";

type Tuser = {
  [keys: string]: any;
};

interface UserProps {}

const User: FunctionComponent<UserProps> = () => {
  const { admin } = ROUTES;
  const { id } = useParams();
  const redirect = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<Tuser>({});
  const [userPassword, setUserPassword] = useState("");
  const [projects, setProjects] = useState<Tuser[]>([]);
  const [options, setOptions] = useState([
    { label: "asdasd", value: "asdsd" },
    { label: "asdasd", value: "adsasdsd" },
    { label: "asdasd", value: "asdsdfas" },
    { label: "asdasd", value: "asdsasdd" },
  ]);

  useEffect(() => {
    setLoading(true);
    retrieveUserById(id)
      .then((res) => setUser(res.data))
      .catch((err) => redirect(admin.users));
    retrieveProjectsList().then((res) => {
      setProjects(res.data);
      setLoading(false);
    });
  }, []);

  const changeInputsValueHandler = (key: string, value: string) => {
    setUser({ ...user, [key]: value });
  };
  const closeModalHandler = () => setShowModal(false);
  const deleteProjectHandler = () => {
    closeModalHandler();
    deleteUser(id).then(() => {
      redirect(admin.users);
      toast.success(`User ${user.username} deleted!`);
    });
  };

  const updateUserHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    updateUser(id, user).then((res) => {
      setUser(res.data);
      toast.success("User details updated!");
      if (userPassword.length > 0) {
        if (userPassword.length >= 8)
          updateUserPassword(id, {
            password1: userPassword,
            password2: userPassword,
          })
            .then(() => {
              setUserPassword("");
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              if (err.response.data.password1) {
                if (Array.isArray(err.response.data.password1[0]))
                  toast.error(err.response.data.password1[0][0]);
                else toast.error(err.response.data.password1[0]);
              }
            });
        else {
          setLoading(false);
          toast.error("Password must contain at least 8 characters!");
        }
      } else setLoading(false);
    });
  };

  return loading ? (
    <div className="h-96 w-full">
      <Skeleton baseColor="#d4d4d8" count={4.5} className="h-8" />
      <Skeleton baseColor="#d4d4d8" count={0.5} className="h-8" />
      <Skeleton baseColor="#d4d4d8" count={4.5} className="h-8" />
    </div>
  ) : (
    <form className="px-8" onSubmit={updateUserHandler}>
      <div
        onClick={closeModalHandler}
        className={`fixed inset-0 h-screen w-screen transition-all ${
          showModal ? "z-20 backdrop-blur-sm" : "-z-20"
        }`}
      ></div>
      <div
        className={`fixed left-1/2 w-fit -translate-x-1/2 rounded-md bg-gray-500 px-12 py-4 text-white shadow-lg transition-all ${
          showModal ? "z-30 opacity-100" : "-z-20 opacity-0"
        }`}
      >
        <span>Are you sure?!</span>
        <div className="mt-4 flex gap-4">
          <button
            type="button"
            onClick={closeModalHandler}
            className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            NO!
          </button>
          <button
            onClick={deleteProjectHandler}
            type="button"
            className="rounded-md border-2 border-gray-600 bg-white px-12 py-2 text-gray-600 transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            Delete!
          </button>
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        <TextInput
          id="username"
          disabled
          value={user.username}
          label="Username"
        />
        <TextInput
          id="email"
          value={user.email}
          label="Email"
          onChange={(value) => changeInputsValueHandler("email", value)}
        />
        <TextInput
          id="firstName"
          value={user.first_name}
          label="FirstName"
          onChange={(value) => changeInputsValueHandler("first_name", value)}
        />
        <TextInput
          id="password"
          value={userPassword}
          type="password"
          label="Password"
          onChange={setUserPassword}
        />
      </div>
      <div className="mt-4 w-3/4 sm:w-1/2 lg:w-1/4">
        <SelectOptions label="Project" options={options} isMulti={true} />
      </div>
      <div className="mt-4 flex gap-4">
        <button className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800">
          Save
        </button>
        <Link
          to={admin.users}
          className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Back
        </Link>
        <button
          onClick={() => setShowModal(true)}
          type="button"
          className={`${
            user.is_superuser ? "hidden" : "block"
          } rounded-md border-2 border-gray-600 bg-white px-12 py-2 text-gray-600 transition-colors hover:bg-gray-400 hover:text-gray-800`}
        >
          Delete User
        </button>
      </div>
    </form>
  );
};

export default User;

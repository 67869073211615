import { FormEvent, FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "../../routes/ROUTES";
import { createUser } from "../../services/postServices";
import Spinner from "../common/Spinner";
import TextInput from "../common/TextInput";

interface CreateUserProps {}

const CreateUser: FunctionComponent<CreateUserProps> = () => {
  const { admin } = ROUTES;
  const redirect = useNavigate();

  const [userInfo, setUserInfo] = useState({
    username: "",
    password1: "",
    password2: "",
  });
  const [loading, setLoading] = useState(false);

  const changeInputsValueHandler = (key: string, value: string) => {
    setUserInfo({ ...userInfo, [key]: value });
  };
  const createUserHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userInfo.username && userInfo.password1 && userInfo.password2) {
      if (userInfo.password1.length >= 8) {
        setLoading(true);
        createUser(userInfo)
          .then(() => {
            redirect(admin.users);
            toast.success("New User Created!");
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.data.detail) toast.error(err.response.data.detail);
            else if (
              err.response.data.username &&
              err.response.data.username.length
            )
              toast.error(err.response.data.username[0]);
            else if (
              err.response.data.password1 &&
              err.response.data.password1.length
            )
              if (Array.isArray(err.response.data.password1[0]))
                toast.error(err.response.data.password1[0][0]);
              else toast.error(err.response.data.password1[0]);
          });
      } else toast.error("Password must contain at least 8 characters!");
    } else toast.error("All fields are required!");
  };
  return (
    <div className="mx-auto flex w-fit flex-col items-center gap-2 rounded-lg bg-white p-4 shadow-md">
      <h1 className="font-bold lg:text-2xl">Create User</h1>
      <form onSubmit={createUserHandler}>
        <div className="flex flex-col gap-4 px-8">
          <TextInput
            id="username"
            value={userInfo.username}
            label="Username"
            onChange={(value) => changeInputsValueHandler("username", value)}
          />
          <TextInput
            id="password1"
            value={userInfo.password1}
            type="password"
            label="Password"
            onChange={(value) => changeInputsValueHandler("password1", value)}
          />
          <TextInput
            id="password2"
            value={userInfo.password2}
            type="password"
            label="Confirm Password"
            onChange={(value) => changeInputsValueHandler("password2", value)}
          />
          <button
            className="flex w-full justify-center rounded-md bg-gray-600 py-2 text-white"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;

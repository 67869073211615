import { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ROUTES from "../routes/ROUTES";
import {
  retrieveProjectBySlug,
  retrieveUser,
  retrieveUserProject,
} from "../services/getServices";
import accessToken from "../utils/accessToken";

type Tproject = {
  [key: string]: any;
};
interface ProjectPageProps {}

const ProjectPage: FunctionComponent<ProjectPageProps> = () => {
  const { slug } = useParams();
  const redirect = useNavigate();
  const { pathname } = useLocation();
  const [project, setProject] = useState<Tproject>({});
  const [hasAccess, setHasAccess] = useState<string | boolean>("loading");
  const { auth } = ROUTES;

  useEffect(() => {
    const token = accessToken();
    if (token) {
      retrieveUserProject(slug)
        .then((res) => {
          setProject(res.data);
          setHasAccess(true);
        })
        .catch(() => {
          retrieveUser().then((res) => {
            if (!res.data.is_superuser) setHasAccess(false);
            else {
              retrieveProjectBySlug(slug)
                .then((res) => setProject(res.data))
                .catch((err) => redirect("/404"));
              setHasAccess(true);
            }
          });
        });
    } else {
      redirect(`${auth.login}?back=${pathname}`);
    }
  }, []);
  return hasAccess === true ? (
    <>
      <hr />
      <section>
        <iframe
          title={project.slug}
          className="h-[850px] w-full border-none"
          src={project.url}
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </section>
      <hr />
    </>
  ) : hasAccess === "loading" ? (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex items-center justify-center ">
        <div className="h-40 w-40 animate-spin rounded-full border-t-4 border-b-4 border-green-900"></div>
      </div>
    </div>
  ) : (
    <div className="flex h-screen w-screen items-center justify-center text-3xl font-bold">
      You dont have access or this project is not exists!
    </div>
  );
};

export default ProjectPage;

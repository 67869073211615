import http from "./httpServices";

export const updateProject = async (projectSlug, data) => {
  return await http.patch(`user/project/${projectSlug}/`, data);
};

export const updateCompany = async (companyName, data) => {
  return await http.patch(`user/company/${companyName}/`, data);
};

export const updateUser = async (userId, data) => {
  return await http.put(`auth/update/${userId}/`, data);
};

export const updateUserPassword = async (userId, data) => {
  return await http.put(`auth/update/password/${userId}/`, data);
};

import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "../../routes/ROUTES";
import { retrieveCompaniesList } from "../../services/getServices";
import { createProject } from "../../services/postServices";
import SelectOptions from "../common/SelectOptions";
import Spinner from "../common/Spinner";
import TextInput from "../common/TextInput";

type Tcompany = {
  [keys: string]: any;
};

interface CreateProjectProps {}

const CreateProject: FunctionComponent<CreateProjectProps> = () => {
  const { admin } = ROUTES;
  const redirect = useNavigate();
  const [projectInfo, setProjectInfo] = useState({
    name: "",
    url: "",
    slug: "",
    company: null,
  });
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Tcompany[]>([]);

  useEffect(() => {
    retrieveCompaniesList()
      .then((res) => {
        const companiesOptions = res.data.map((company: Tcompany) => {
          return { label: company.name, value: company.id };
        });
        setCompanies(companiesOptions);
      })
      .catch((err) => {});
  }, []);

  const changeInputsValueHandler = (key: string, value: string | number) => {
    setProjectInfo({ ...projectInfo, [key]: value });
  };

  const createProjectHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (projectInfo.name && projectInfo.url && projectInfo.slug) {
      setLoading(true);
      createProject(projectInfo)
        .then((res) => {
          setLoading(false);
          redirect(admin.projects + "/" + res.data.slug);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else toast.error("All fields are required!");
  };

  return (
    <div className="mx-auto flex w-fit flex-col items-center gap-4 rounded-lg bg-white p-4 shadow-md">
      <h1 className="font-bold lg:text-2xl">Create Project</h1>
      <form onSubmit={createProjectHandler}>
        <div className="flex flex-col gap-2 px-8">
          <TextInput
            id="name"
            value={projectInfo.name}
            label="Name"
            onChange={(value) => changeInputsValueHandler("name", value)}
          />
          <TextInput
            id="url"
            value={projectInfo.url}
            label="Url"
            onChange={(value) => changeInputsValueHandler("url", value)}
          />
          <TextInput
            id="slug"
            value={projectInfo.slug}
            label="Slug"
            onChange={(value) => changeInputsValueHandler("slug", value)}
          />
          <SelectOptions
            label="Company"
            placeholder="Optional"
            options={companies}
            isMulti={false}
            onChange={(value) =>
              changeInputsValueHandler("company", value.value)
            }
          />
          <button
            className="flex w-full justify-center rounded-md bg-gray-600 py-2 text-white"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;

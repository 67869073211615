import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { retrieveCompanyByName } from "../../services/getServices";
import TextInput from "../common/TextInput";
import ROUTES from "../../routes/ROUTES";
import { updateCompany } from "../../services/updateServices";
import { Link } from "react-router-dom";
import { deleteCompany } from "../../services/deleteServices";

type Tcompany = {
  [keys: string]: any;
};

interface CompanyProps {}

const Company: FunctionComponent<CompanyProps> = () => {
  const { admin } = ROUTES;
  const { name } = useParams();
  const redirect = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Tcompany>({});

  useEffect(() => {
    setLoading(true);
    retrieveCompanyByName(name)
      .then((res) => {
        setCompany(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        redirect(admin.companies);
      });
  }, []);

  const changeInputsValueHandler = (value: string) => {
    setCompany({ name: value });
  };

  const closeModalHandler = () => setShowModal(false);
  const deleteProjectHandler = () => {
    closeModalHandler();
    deleteCompany(name)
      .then(() => {
        redirect(admin.companies);
        toast.success(`Company ${name} deleted!`)
      })
      .catch(() => redirect(admin.companies));
  };

  const updateCompanyHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (company.name) {
      setLoading(true);
      updateCompany(name, company)
        .then((res) => {
          setCompany(res.data);
          redirect(admin.companies + "/" + res.data.name, { replace: true });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else toast.error("Company name is required!");
  };

  return loading ? (
    <div className="h-96 w-full">
      <Skeleton baseColor="#d4d4d8" count={4.5} className="h-8" />
      <Skeleton baseColor="#d4d4d8" count={0.5} className="h-8" />
      <Skeleton baseColor="#d4d4d8" count={4.5} className="h-8" />
    </div>
  ) : (
    <form className="px-8" onSubmit={updateCompanyHandler}>
      <div
        onClick={closeModalHandler}
        className={`fixed inset-0 h-screen w-screen transition-all ${
          showModal ? "z-20 backdrop-blur-sm" : "-z-20"
        }`}
      ></div>
      <div
        className={`fixed left-1/2 w-fit -translate-x-1/2 rounded-md bg-gray-500 px-12 py-4 text-white shadow-lg transition-all ${
          showModal ? "z-30 opacity-100" : "-z-20 opacity-0"
        }`}
      >
        <span>Are you sure?!</span>
        <div className="mt-4 flex gap-4">
          <button
            type="button"
            onClick={closeModalHandler}
            className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            NO!
          </button>
          <button
            onClick={deleteProjectHandler}
            type="button"
            className="rounded-md border-2 border-gray-600 bg-white px-12 py-2 text-gray-600 transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            Delete!
          </button>
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        <TextInput id="id" disabled value={company.id} label="Id" />
        <TextInput
          id="name"
          value={company.name}
          label="Name"
          onChange={(value) => changeInputsValueHandler(value)}
        />
      </div>
      <div className="mt-4 flex gap-4">
        <button
          type="submit"
          className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Save
        </button>
        <Link
          to={admin.companies}
          className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Back
        </Link>
        <button
          onClick={() => setShowModal(true)}
          type="button"
          className="rounded-md border-2 border-gray-600 bg-white px-12 py-2 text-gray-600 transition-colors hover:bg-gray-400 hover:text-gray-800"
        >
          Delete Company
        </button>
      </div>
    </form>
  );
};

export default Company;

import { ChangeEvent, FormEvent, FunctionComponent } from "react";

interface TextInputProps {
  id: string;
  label?: string;
  value?: string;
  type?: "text" | "password";
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const TextInput: FunctionComponent<TextInputProps> = ({
  id,
  label,
  value = "",
  type,
  placeholder,
  disabled,
  onChange,
}) => {
  const changeInputValueHandler = (e: ChangeEvent<HTMLInputElement>) =>
    onChange?.(e.target.value);
  return (
    <div className="flex flex-col gap-1">
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type || "text"}
        id={id}
        value={value || ""}
        placeholder={placeholder}
        disabled={disabled}
        onChange={changeInputValueHandler}
        className="rounded-md border-2 border-gray-300 py-1 px-2 outline-none focus:border-gray-400 lg:w-80"
      />
    </div>
  );
};

export default TextInput;

import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { retrieveUser } from "../services/getServices";
import { loginUser } from "../services/postServices";
import accessToken from "../utils/accessToken";
import Spinner from "./../components/common/Spinner";
import TextInput from "./../components/common/TextInput";

interface LoginPageProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const search = useSearchParams();
  const redirect = useNavigate();

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = accessToken();
    if (token) {
      setIsLoggedIn(true);
      retrieveUser().then((res) => {
        if (res.data.is_superuser) {
          redirect("/admin");
        } else redirect("/");
      });
    }
  }, []);

  const changeInputsValueHandler = (key: string, value: string) => {
    setUserInfo({ ...userInfo, [key]: value });
  };
  const loginHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userInfo.username.length && userInfo.password.length) {
      setLoading(true);
      loginUser(userInfo)
        .then((res) => {
          const back = search[0].get("back");
          localStorage.setItem("access", res.data.access);
          localStorage.setItem("refresh", res.data.refresh);
          if (back) redirect(String(back));
          else
            retrieveUser().then((res) => {
              if (res.data.is_superuser) {
                redirect("/admin");
              } else redirect("/");
            });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.detail) toast.error(err.response.data.detail);
        });
    } else toast.error("Username or password is blank!");
  };

  return !isLoggedIn ? (
    <div className="mx-auto mt-12 flex w-fit flex-col items-center gap-4 rounded-lg bg-white p-4 shadow-md">
      <h1 className="font-bold lg:text-2xl">Login</h1>
      <form onSubmit={loginHandler}>
        <div className="flex flex-col gap-4 px-8">
          <TextInput
            id="username"
            value={userInfo.username}
            label="Username"
            onChange={(value) => changeInputsValueHandler("username", value)}
          />
          <TextInput
            id="password"
            value={userInfo.password}
            type="password"
            label="Password"
            onChange={(value) => changeInputsValueHandler("password", value)}
          />
          <button
            className="flex w-full justify-center rounded-md bg-gray-600 py-2 text-white"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Login"}
          </button>
        </div>
      </form>
    </div>
  ) : (
    <div></div>
  );
};

export default LoginPage;

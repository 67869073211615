import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../components/common/Sidebar";
import ROUTES from "../routes/ROUTES";
import { retrieveUser } from "../services/getServices";
import accessToken from "../utils/accessToken";

interface AdminPageProps {}

const AdminPage: FunctionComponent<AdminPageProps> = () => {
  const redirect = useNavigate();
  const { pathname } = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { admin, auth } = ROUTES;

  const closeModalHandler = () => setShowModal(false);
  const logoutUserHandler = () => {
    closeModalHandler();
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    redirect(auth.login);
  };

  useEffect(() => {
    const token = accessToken();
    if (token) {
      retrieveUser().then((res) => {
        if (res.data.is_superuser) {
          (pathname === "/admin" || pathname === "/admin/") &&
            redirect("/admin/users");
          setIsAdmin(true);
        } else {
          redirect("/");
          toast.error("You are not admin!");
        }
      });
    } else {
      redirect(`${auth.login}?back=${pathname}`);
    }
  }, [pathname]);
  return isAdmin ? (
    <section className="mt-6 p-4">
      <div
        onClick={closeModalHandler}
        className={`fixed inset-0 h-screen w-screen transition-all ${
          showModal ? "z-20 backdrop-blur-sm" : "-z-20"
        }`}
      ></div>
      <div
        className={`fixed left-1/2 w-fit -translate-x-1/2 rounded-md bg-gray-500 px-12 py-4 text-white shadow-lg transition-all ${
          showModal ? "z-30 opacity-100" : "-z-20 opacity-0"
        }`}
      >
        <span>Are you sure?!</span>
        <div className="mt-4 flex gap-4">
          <button
            type="button"
            onClick={closeModalHandler}
            className="rounded-md bg-gray-600 px-12 py-2 text-white transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            NO!
          </button>
          <button
            onClick={logoutUserHandler}
            type="button"
            className="rounded-md border-2 border-gray-600 bg-white px-12 py-2 text-gray-600 transition-colors hover:bg-gray-400 hover:text-gray-800"
          >
            Logout!
          </button>
        </div>
      </div>
      <div className="ml-auto mb-4 flex w-fit gap-2 rounded-md bg-gray-500 p-2">
        <NavLink
          to={admin.createUser}
          className={({ isActive }) =>
            isActive ? "sidebarItem sidebarItemActive" : "sidebarItem"
          }
        >
          Create User
        </NavLink>
        <NavLink
          to={admin.createProject}
          className={({ isActive }) =>
            isActive ? "sidebarItem sidebarItemActive" : "sidebarItem"
          }
        >
          Create Project
        </NavLink>
        <NavLink
          to={admin.createCompany}
          className={({ isActive }) =>
            isActive ? "sidebarItem sidebarItemActive" : "sidebarItem"
          }
        >
          Create Company
        </NavLink>
        <button className="sidebarItem" onClick={() => setShowModal(true)}>
          Logout
        </button>
      </div>
      <div className="flex">
        <Sidebar />
        <Outlet />
      </div>
    </section>
  ) : (
    <div></div>
  );
};

export default AdminPage;

import http from "./httpServices";

export const deleteProject = async (projectSlug) => {
  return await http.delete(`user/project/${projectSlug}/`);
};

export const deleteCompany = async (companyName) => {
  return await http.delete(`user/company/${companyName}/`);
};

export const deleteUser = async (userId) => {
  return await http.delete(`auth/delete/${userId}`);
};

import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { retrieveUsersList } from "../../services/getServices";
import UserIcon from "../common/icons/UserIcon";

type Tusers = {
  [keys: string]: any;
};

interface UsersProps {}

const Users: FunctionComponent<UsersProps> = () => {
  const redirect = useNavigate();

  const [users, setUsers] = useState<Tusers[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    retrieveUsersList().then((res) => {
      setUsers(res.data);
      setLoading(false);
    });
  }, []);

  const showUserInfoHandler = (id: string | number) =>
    redirect(`/admin/user/${id}`);

  return loading ? (
    <div className="h-96 w-full">
      <Skeleton baseColor="#d4d4d8" count={10} className="h-8" />
    </div>
  ) : (
    <div className="mx-auto w-[80%]">
      {users.length ? (
        <table className="flex max-h-[78vh] w-full flex-col shadow-lg">
          <thead>
            <tr className="flex rounded-tr-md rounded-tl-md bg-gray-600 px-2 text-sm text-white lg:text-lg">
              <th className="tableItem">Id</th>
              <th className="tableItem">Username</th>
              <th className="tableItem">Email</th>
              <th className="tableItem">FirstName</th>
            </tr>
          </thead>
          <tbody className="flex h-full flex-col overflow-auto text-lg">
            {users.map((user) => (
              <tr
                key={user.id}
                className="flex px-2 odd:bg-gray-200 even:bg-gray-300"
                onClick={() => showUserInfoHandler(user.id)}
              >
                <td className="tableItem cursor-pointer">{user.id}</td>
                <td className="tableItem flex cursor-pointer items-center gap-2">
                  {user.username}
                  {user.is_superuser && (
                    <div className="h-6 w-6">
                      <UserIcon />
                    </div>
                  )}
                </td>
                <td className="tableItem cursor-pointer">{user.email}</td>
                <td className="tableItem cursor-pointer">{user.firstName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-20 text-center text-3xl font-bold">
          There is no user here!
        </div>
      )}
    </div>
  );
};

export default Users;

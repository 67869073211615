import http from "./httpServices.js";

export const retrieveUser = async () => {
  return await http.get("/auth/retrieve/");
};

export const retrieveUserById = async (id) => {
  return await http.get(`/auth/retrieve/${id}/`);
};

export const retrieveUserProject = async (projectSlug) => {
  return await http.get(`/user/project/retrieve/${projectSlug}/`);
};

export const retrieveProjectsList = async () => {
  return await http.get("/user/project/");
};

export const retrieveProjectBySlug = async (projectSlug) => {
  return await http.get(`/user/project/${projectSlug}/`);
};

export const retrieveUsersList = async () => {
  return await http.get(`/auth/list/`);
};

export const retrieveCompaniesList = async () => {
  return await http.get(`/user/company/`);
};

export const retrieveCompanyByName = async (name) => {
  return await http.get(`/user/company/${name}/`);
};

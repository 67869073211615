import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminPage from "./pages/AdminPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ProjectPage from "./pages/ProjectPage";
import Page404 from "./pages/Page404";
import Users from "./components/Users/Users";
import Projects from "./components/Projects/Projects";
import User from "./components/Users/User";
import CreateUser from "./components/Users/CreateUser";
import CreateProject from "./components/Projects/CreateProject";
import Project from "./components/Projects/Project";
import "./App.css";
import Companies from "./components/Companies/Companies";
import Company from "./components/Companies/Company";
import CreateCompany from "./components/Companies/CreateCompany";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "Arqaam";
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin/*" element={<AdminPage />}>
          <Route path="users" element={<Users />} />
          <Route path="user/:id" element={<User />} />
          <Route path="create-user" element={<CreateUser />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:slug" element={<Project />} />
          <Route path="create-project" element={<CreateProject />} />
          <Route path="companies" element={<Companies />} />
          <Route path="companies/:name" element={<Company />} />
          <Route path="create-company" element={<CreateCompany />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/projects/:slug" element={<ProjectPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;

import { FunctionComponent } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

type Toptions = {
  [key: string]: any;
};

interface SelectOptionsProps {
  readonly options: readonly Toptions[];
  readonly label?: string;
  readonly placeholder?: string;
  value?: Toptions;
  isMulti: boolean;
  onChange?: (options: Toptions) => void;
}

const SelectOptions: FunctionComponent<SelectOptionsProps> = ({
  label,
  placeholder,
  options,
  value,
  isMulti,
  onChange,
}) => {
  const customStyles = {
    valueContainer: (provided: any) => {
      const maxHeight = "8rem";
      const overflow = "auto";
      return { ...provided, maxHeight, overflow };
    },
  };
  const changeOptionsHandler = (e: Toptions[]) => onChange?.(e);

  return (
    <div className="flex flex-col gap-1">
      {label && <span>{label}</span>}
      <Select
        closeMenuOnSelect={!isMulti}
        components={animatedComponents}
        isMulti={isMulti}
        options={options}
        styles={customStyles}
        value={value}
        placeholder={placeholder}
        onChange={changeOptionsHandler}
      />
    </div>
  );
};

export default SelectOptions;

import axios from "axios";
import ROUTES from "../routes/ROUTES";
import accessToken from "../utils/accessToken";
const configuredAxios = axios.create({
  baseURL: "https://api.arqaam.io/",
});
const http = {
  get: configuredAxios.get,
  post: configuredAxios.post,
  put: configuredAxios.put,
  patch: configuredAxios.patch,
  delete: configuredAxios.delete,
};

configuredAxios.interceptors.request.use((config) => {
  const token = accessToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Response interceptor for API calls
configuredAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data.code === "token_not_valid" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const { auth } = ROUTES;
      http
        .post("auth/api/token/refresh/", {
          refresh: localStorage.getItem("refresh"),
        })
        .then((res) => {
          const access = res.data.access;
          localStorage.setItem("access", access);
          axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
          return configuredAxios(originalRequest);
        })
        .catch(() => {
          window.location.href = `${auth.login}?back=${window.location.pathname}`;
          localStorage.clear();
        });
    }
    return Promise.reject(error);
  }
);

export default http;

import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ROUTES from "../../routes/ROUTES";
import { retrieveProjectsList } from "../../services/getServices";

type Tprojects = {
  [keys: string]: any;
};

interface ProjectsProps {}

const Projects: FunctionComponent<ProjectsProps> = () => {
  const { admin, projects: projectsPath } = ROUTES;
  const redirect = useNavigate();

  const [projects, setProjects] = useState<Tprojects[]>([]);
  const [loading, setLoading] = useState(false);

  const showProjectInfoHandler = (slug: string | number) =>
    redirect(admin.projects + "/" + slug);

  const showProjectUrlHandler = (slug: string) => {
    redirect(projectsPath + "/" + slug);
  };

  useEffect(() => {
    setLoading(true);
    retrieveProjectsList().then((res) => {
      setProjects(res.data);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <div className="h-96 w-full">
      <Skeleton baseColor="#d4d4d8" count={10} className="h-8" />
    </div>
  ) : (
    <div className="mx-auto w-[80%]">
      {projects.length ? (
        <table className="flex w-full flex-col max-h-[78vh] shadow-lg">
          <thead>
            <tr className="flex rounded-tr-md rounded-tl-md bg-gray-600 px-2 text-sm text-white lg:text-lg">
              <th className="tableItem">Name</th>
              <th className="tableItem">Url</th>
              <th className="tableItem">Slug</th>
              <th className="tableItem">Company</th>
            </tr>
          </thead>
          <tbody className="flex h-full flex-col overflow-auto text-lg">
            {projects.map((project) => (
              <tr
                key={project.id}
                className="flex px-2 text-center odd:bg-gray-200 even:bg-gray-300"
              >
                <td
                  onClick={() => showProjectInfoHandler(project.slug)}
                  className="tableItem cursor-pointer"
                >
                  {project.name}
                </td>
                <td
                  onClick={() => showProjectInfoHandler(project.slug)}
                  className="tableItem cursor-pointer"
                >
                  {project.url}
                </td>
                <td
                  onClick={() => showProjectUrlHandler(project.slug)}
                  className="tableItem cursor-pointer transition-colors hover:text-gray-400"
                >
                  {project.slug}
                </td>
                <td
                  onClick={() => showProjectInfoHandler(project.slug)}
                  className="tableItem cursor-pointer"
                >
                  {project.company}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-20 text-center text-3xl font-bold">
          There is no project here!
        </div>
      )}
    </div>
  );
};

export default Projects;

import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ROUTES from "../../routes/ROUTES";
import { retrieveCompaniesList } from "../../services/getServices";

type Tcompanies = {
  [keys: string]: any;
};

interface CompaniesProps {}

const Companies: FunctionComponent<CompaniesProps> = () => {
  const { admin } = ROUTES;
  const redirect = useNavigate();

  const [companies, setCompanies] = useState<Tcompanies[]>([]);
  const [loading, setLoading] = useState(false);

  const showCompanyInfoHandler = (name: string | number) =>
    redirect(admin.companies + "/" + name);

  useEffect(() => {
    setLoading(true);
    retrieveCompaniesList()
      .then((res) => {
        setCompanies(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <div className="h-96 w-full">
      <Skeleton baseColor="#d4d4d8" count={10} className="h-8" />
    </div>
  ) : (
    <div className="mx-auto w-[80%]">
      {companies.length ? (
        <table className="flex max-h-[78vh] w-full flex-col shadow-lg">
          <thead>
            <tr className="flex rounded-tr-md rounded-tl-md bg-gray-600 px-2 text-sm text-white lg:text-lg">
              <th className="tableItem min-w-[50%_!important]">Name</th>
              <th className="tableItem min-w-[50%_!important]">Id</th>
            </tr>
          </thead>
          <tbody className="flex h-full flex-col overflow-auto text-lg">
            {companies.map((company) => (
              <tr
                key={company.name}
                className="flex px-2 text-center odd:bg-gray-200 even:bg-gray-300"
              >
                <td
                  onClick={() => showCompanyInfoHandler(company.name)}
                  className="tableItem min-w-[50%_!important] cursor-pointer"
                >
                  {company.name}
                </td>
                <td
                  onClick={() => showCompanyInfoHandler(company.name)}
                  className="tableItem min-w-[50%_!important] cursor-pointer"
                >
                  {company.id}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-20 text-center text-3xl font-bold">
          There is no company here!
        </div>
      )}
    </div>
  );
};

export default Companies;
